@import "/src/styles/mixin";

.swiper-season {
  .swiper {
    &-wrapper {
      padding-bottom: 32px;
    }
    &-button {
      @include arrowSwiper;
    }
    &-pagination {
      @include swiperPagination;
    }
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
    }
  }
}
