@import "/src/styles/mixin";

.item {
  position: relative;
  overflow: hidden;
  &:hover {
    .img {
      transform: scale(0.98);
    }
  }
}
.img {
  height: 100%;
  display: block;
  margin-bottom: 10px;
  transition: transform 0.2s ease;
}
.list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.listLink {
  @include font14;
  @include lineHover;
}
.listItem {
  span {
    margin: 0 5px;
  }
}
